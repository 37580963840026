import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Layout from "components/layouts/Common";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Tag from "components/pages/front/Tag";

type Props = {
  data: any;
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext } = props;
  const { tagArticles, tag, categories, footers } = pageContext;

  const articles = tagArticles
    ? tagArticles.map((item: any) => item.article)
    : [];

  return (
    <>
      <GatsbySeo
        title={`${tag.name} 舵オンライン │ 船遊びの情報サイト `}
        description="Kazi Online"
        openGraph={{
          url: `https://www.kazi-online.com/tags/${tag.slug}`,
          title: `${tag.name} 舵オンライン │ 船遊びの情報サイト`,
          description: "Kazi Online",
          type: "website",
          site_name: "舵オンライン │ 船遊びの情報サイト ",
          images: [
            {
              url:
                "https://kazi-online.com/images/KAZIonline_SHARE_1200×630pix.jpg",
              alt: "舵オンライン │  船遊びの情報サイト（舵社）",
            },
          ],
        }}
      />
      <Helmet>
        <title>{`${tag.name} 舵オンライン │ 船遊びの情報サイト `}</title>
      </Helmet>
      <Layout footers={footers}>
        <Tag tag={tag} articles={articles} categories={categories} />
      </Layout>
    </>
  );
}

// export const query = graphql`
//   query($tagID: ID) {
//     appsync {
//       listTags(filter: { id: { eq: $tagID } }) {
//         items {
//           articles {
//             items {
//               article {
//                 adID
//                 authorID
//                 category {
//                   id
//                   jName
//                   name
//                   slug
//                 }
//                 tags {
//                   items {
//                     tagID
//                     tag {
//                       name
//                     }
//                   }
//                 }
//                 categoryID
//                 createdAt
//                 description
//                 mainImage {
//                   url
//                   name
//                 }
//                 prFlg
//                 slug
//                 startDateTime
//                 status
//                 updatedAt
//                 title
//               }
//               createdAt
//               articleID
//               id
//             }
//           }
//         }
//       }
//     }
//   }
// `;
