import React from "react";
import CategoryAritlcesBlock from "components/organisims/articles/CategoryAritlcesBlock";
import Container from "components/atoms/Container";
import Header from "components/uikit/Header/Header";
import compareDesc from "date-fns/compareAsc";
import ResponsiveComponent from "components/atoms/ResponsiveComponent";

type Props = {
  articles: any;
  categories: any;
  tag: any;
};

export default function Page(props: Props) {
  const { tag, articles, categories } = props;

  return (
    <>
      <Header
        color="white"
        brand="Kazi ONLINE"
        fixed
        changeColorOnScroll={{
          height: 0,
          color: "white",
        }}
      />
      <ResponsiveComponent
        sp={
          <div
            style={{
              paddingTop: "140px",
            }}
          >
            <Container style={{ boxShadow: "none" }}>
              <CategoryAritlcesBlock tag={tag} articles={articles} />
            </Container>
          </div>
        }
        pc={
          <div
            style={{
              paddingTop: "100px",
            }}
          >
            <Container style={{ boxShadow: "none" }}>
              <CategoryAritlcesBlock tag={tag} articles={articles} />
            </Container>
          </div>
        }
      />
    </>
  );
}
